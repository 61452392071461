import H2 from '@library/Texts/H2'
import P from '@library/Texts/Paragraph'
import Column from '@layout/Column/Column'
import Button from '@library/Button/Button'
import Container from '@layout/Container/Container'
import Background from '@library/Background/Background'
import ColumnContainer from '@layout/Column/Container/Container'

import t from '@/translations/v2/reusable/shop'

export default function ShopInfo({ locale }) {
  return (
    <Background className="backgroundShopSection">
      <Container>
        <ColumnContainer gap={50} wrap="wrap">
          <Column width={66}>
            <H2>{t.title[locale]}</H2>
            <P size="medium">{t.text[locale]}</P>
            <Button size="medium" url="https://kayakomat.shop/">
              {t.button[locale]}
            </Button>
          </Column>
        </ColumnContainer>
      </Container>
    </Background>
  )
}
