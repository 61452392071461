module.exports = {
    "homeTitle": {
        sv: 'KAYAKOMAT',
        en: 'KAYAKOMAT',
        de: 'KAYAKOMAT',
        es: 'KAYAKOMAT',
        dk: 'KAYAKOMAT',
        fr: 'KAYAKOMAT' 
    },
    "homeDescription": {
        sv: 'KAYAKOMAT',
        en: 'KAYAKOMAT',
        de: 'KAYAKOMAT',
        es: 'KAYAKOMAT',
        dk: 'KAYAKOMAT',
        fr: 'KAYAKOMAT'
    },
    "locationsTitle": {
        sv: 'KAYAKOMAT - Stations',
        en: 'KAYAKOMAT - Stations',
        de: 'KAYAKOMAT - Stations',
        es: 'KAYAKOMAT - Stations',
        dk: 'KAYAKOMAT - Stations',
        fr: 'KAYAKOMAT - Stations'
    },
    "locationsDescription": {
        sv: 'KAYAKOMAT',
        en: 'KAYAKOMAT',
        de: 'KAYAKOMAT',
        es: 'KAYAKOMAT',
        dk: 'KAYAKOMAT',
        fr: 'KAYAKOMAT'
    },
    "faqTitle": {
        sv: 'KAYAKOMAT',
        en: 'KAYAKOMAT',
        de: 'KAYAKOMAT',
        es: 'KAYAKOMAT',
        dk: 'KAYAKOMAT',
        fr: 'KAYAKOMAT' 
    },
    "faqDescription": {
        sv: 'KAYAKOMAT',
        en: 'KAYAKOMAT',
        de: 'KAYAKOMAT',
        es: 'KAYAKOMAT',
        dk: 'KAYAKOMAT',
        fr: 'KAYAKOMAT'
    },
    "contactTitle": {
        sv: 'KAYAKOMAT',
        en: 'KAYAKOMAT',
        de: 'KAYAKOMAT',
        es: 'KAYAKOMAT',
        dk: 'KAYAKOMAT',
        fr: 'KAYAKOMAT' 
    },
    "contactDescription": {
        sv: 'KAYAKOMAT',
        en: 'KAYAKOMAT',
        de: 'KAYAKOMAT',
        es: 'KAYAKOMAT',
        dk: 'KAYAKOMAT',
        fr: 'KAYAKOMAT'
    },
    "safetyTitle": {
        sv: 'KAYAKOMAT',
        en: 'KAYAKOMAT',
        de: 'KAYAKOMAT',
        es: 'KAYAKOMAT',
        dk: 'KAYAKOMAT',
        fr: 'KAYAKOMAT' 
    },
    "safetyDescription": {
        sv: 'KAYAKOMAT',
        en: 'KAYAKOMAT',
        de: 'KAYAKOMAT',
        es: 'KAYAKOMAT',
        dk: 'KAYAKOMAT',
        fr: 'KAYAKOMAT'
    },
    "aboutTitle": {
        sv: 'KAYAKOMAT',
        en: 'KAYAKOMAT',
        de: 'KAYAKOMAT',
        es: 'KAYAKOMAT',
        dk: 'KAYAKOMAT',
        fr: 'KAYAKOMAT' 
    },
    "aboutDescription": {
        sv: 'KAYAKOMAT',
        en: 'KAYAKOMAT',
        de: 'KAYAKOMAT',
        es: 'KAYAKOMAT',
        dk: 'KAYAKOMAT',
        fr: 'KAYAKOMAT'
    },
    "termsTitle": {
        sv: 'KAYAKOMAT',
        en: 'KAYAKOMAT',
        de: 'KAYAKOMAT',
        es: 'KAYAKOMAT',
        dk: 'KAYAKOMAT',
        fr: 'KAYAKOMAT' 
    },
    "termsDescription": {
        sv: 'KAYAKOMAT',
        en: 'KAYAKOMAT',
        de: 'KAYAKOMAT',
        es: 'KAYAKOMAT',
        dk: 'KAYAKOMAT',
        fr: 'KAYAKOMAT'
    },
    "privacyTitle": {
        sv: 'KAYAKOMAT',
        en: 'KAYAKOMAT',
        de: 'KAYAKOMAT',
        es: 'KAYAKOMAT',
        dk: 'KAYAKOMAT',
        fr: 'KAYAKOMAT' 
    },
    "privacyDescription": {
        sv: 'KAYAKOMAT',
        en: 'KAYAKOMAT',
        de: 'KAYAKOMAT',
        es: 'KAYAKOMAT',
        dk: 'KAYAKOMAT',
        fr: 'KAYAKOMAT'
    },
}