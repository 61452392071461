import { useEffect, useRef, useState } from 'react'

import P from '@library/Texts/Paragraph'
import styles from './Input.module.css'

export default function InputTextAutoComplete({
  placeholder = '',
  value = '',
  onChange,
  items,
  listEmptyMsg = '',
  listItemRender,
  className,
  priorityFields = [],
}) {
  const wrapperRef = useRef(null)
  const [inputValue, setInputValue] = useState(value)
  const [focused, setFocused] = useState(false)
  const onFocus = () => setFocused(true)

  const useOutsideClose = (ref) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (focused && ref.current && !ref.current.contains(event.target)) {
          setFocused(false)
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref, focused])
  }

  useOutsideClose(wrapperRef)

  const valueChange = (value) => {
    setInputValue(value)
    if (onChange !== false) {
      onChange(value)
    }
  }
  const getItems = () => {
    let regexArray = []
    if (inputValue.includes(' ')) {
      for (let v of inputValue.split(' ')) {
        regexArray.push(new RegExp(v, 'i'))
      }
    } else {
      regexArray.push(new RegExp(inputValue, 'i'))
    }
    let keys = []
    if (items.length > 0) {
      keys = Object.keys(items[0])
    }
    if (keys.length > 0) {
      const filteredItems = items.filter((i) => {
        let match = true
        for (let regexp of regexArray) {
          let regexpFound = false
          for (let k of keys) {
            if (i[k] && String(i[k])?.toLowerCase()?.match(regexp)) {
              regexpFound = true
              break
            }
          }
          if (!regexpFound) {
            match = false
            break
          }
        }
        return match
      })
      const sortedItems = filteredItems.map((i) => {
        if (i.inactiveBooking) {
          return {
            ...i,
            __priority: -1,
          }
        } else if (priorityFields.length > 0) {
          let prio = 0
          for (let field of priorityFields) {
            for (let regexp of regexArray) {
              if (
                i[field.key] &&
                String(i[field.key])?.toLowerCase()?.match(regexp)
              ) {
                if (field.prio > prio) {
                  prio = field.prio
                }
              }
            }
          }
          return {
            ...i,
            __priority: prio,
          }
        } else {
          return {
            ...i,
            __priority: 0,
          }
        }
      })
      return sortedItems.sort((a, b) => b.__priority - a.__priority)
    } else {
      return false
    }
  }

  const buildFilteredItems = () => {
    const filteredItems = getItems()
    if (!filteredItems || filteredItems.length < 1) {
      return (
        <div className={styles.NoItemsFound}>
          <P>{listEmptyMsg}</P>
        </div>
      )
    }
    let returnItems = []
    for (let item of filteredItems) {
      returnItems.push(listItemRender(item))
    }
    return returnItems
  }
  return (
    <div className={`${styles.Input} ${className || ''}`} ref={wrapperRef}>
      <input
        placeholder={placeholder}
        value={inputValue}
        onChange={(e) => {
          valueChange(e.target.value)
        }}
        onFocus={onFocus}
      />
      {focused == true && (
        <>
          <div className={styles.InputAutoComplete}>{buildFilteredItems()}</div>
        </>
      )}
    </div>
  )
}
