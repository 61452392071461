module.exports = {
    "titleSelfService": {
            sv: 'KAJAK OCH SUP-UTHYRNING VIA SELF SERVICE',
            en: 'SELF-SERVICE KAYAK & SUP RENTALS',
            de: 'SELF SERVICE MIETSTATION FÜR KAJAKS UND SUP-BOARDS ',
            es: 'ALQUILER DE KAYAK Y PADDLE SURF EN AUTOSERVICIO',
            dk: 'SELVSERVICE KAYaK & SUP UDLEJNING',
            fr: 'LOCATION DE KAYAK ET DE PADDLE EN LIBRE-SERVICE ' 
    }, 
    "subTitleSelfService": {
            sv: 'Boka online för friheten att paddla var du vill, när du vill!',
            en: 'Book online and have the freedom to paddle where you want, when you want!',
            de: 'Buche online und habe die Freiheit zu paddeln, wo und wann immer du willst!',
            es: '¡Reserva en línea y ten la libertad de remar donde quieras, cuando quieras!',
            dk: 'Book online og få friheden til at ro/padle hvor du vil, når du vil!',
            fr: 'Réservez en ligne et profitez de la liberté de pagayer où vous voulez, quand vous voulez !' 
    }, 
    "titleSafety": {
        sv: 'Säkerhetstips för paddling',
        en: 'Kayaking Safety Tips',
        de: 'Sicherheit beim Kajakfahren',
        es: 'Consejos de seguridad para el kayak',
        dk: 'Sikkerhedstips til kajak',
        fr: 'Conseils de sécurité pour le kayak' 
    }, 
    "textSafety": {
        sv: 'Att paddla kajak och SUP är enkelt, särskilt på en lugn dag och med vår stabila och nybörjarvänliga utrustning.  Säkerhet är prioritet nummer ett, så se till att du läser igenom våra riktlinjer innan du ger dig ut på vattnet även om du har paddlat tidigare.',
        en: 'Kayaking and SUP-paddling is easy, especially on a calm day and with our stable and beginner friendly equipment.  Safety is priority number one, so make sure you review our guidelines before you head out on the water, even if you’ve paddled before.',
        de: 'Das Kajakfahren und Stand-Up-Paddling ist unkompliziert, besonders an ruhigen Tagen und mit unserer stabilen, anfängerfreundlichen Ausrüstung. Sicherheit hat höchste Priorität. Lies vor deinem Ausflug aufs Wasser unbedingt unsere Richtlinien, auch wenn du bereits Erfahrung im Paddeln hast.',
        es: 'El kayak y el SUP son fáciles, especialmente en un día tranquilo y más, con nuestro equipo estable y apto para principiantes. La seguridad es la prioridad número uno, así que asegúrate de revisar nuestras pautas antes de salir al agua, incluso si ya has remado antes.',
        dk: 'Kajak og SUP-paddling er let, især på en rolig dag og med vores stabile og begyndervenlige udstyr. Sikkerhed er førsteprioritet, så sørg for at gennemgå vores retningslinjer, før du begiver dig ud på vandet, selvom du har padlet før.',
        fr: 'Le kayak et la pratique du paddle sont faciles, surtout par une journée calme et avec notre matériel stable et convivial pour les débutants. La sécurité est notre priorité, alors assurez-vous de revoir nos directives avant de partir sur l\'eau, même si vous avez déjà pagayé auparavant.' 
    },
    "titleStart": {
        sv: 'Nyfiken på att starta ditt eget företag? Bli franchisetagare för KAYAKOMAT',
        en: 'Looking to start your own business? Become a KAYAKOMAT Franchisee',
        de: 'Möchtest du dein eigenes Business starten? Werde KAYAKOMAT-Partner!',
        es: '¿Quieres iniciar tu propio negocio? Conviértete en franquiciado de KAYAKOMAT',
        dk: 'Ønsker du at starte din egen virksomhed? Bliv en KAYAKOMAT-franchisetager',
        fr: 'Vous souhaitez créer votre propre entreprise ? Devenez propriétaire de votre propre KAYAKOMAT' 
    }, 
    "textStart": {
        sv: 'Att starta en uthyrningsverksamhet som är automatiserad är ett sidoprojekt med både låg investering och låg ansträngning, men som kan ge fantastiska resultat! Ingen tidigare erfarenhet av paddling eller entreprenörskap behövs. Vårt beprövade koncept gör det till en rolig första satsning på att starta ditt eget företag. Ta KAYAKOMAT till din stad!',
        en: 'Starting an automated rental business is a low investment, low effort side project that can give great results! No previous experience with paddling or as an entrepreneur needed, our proven concept makes it a fun first venture into starting your own business. Bring KAYAKOMAT to your town!',
        de: 'Mit dem Aufbau eines automatisierten Vermietungsgeschäfts investierst du wenig und benötigst nur geringen Aufwand für ein nebenberufliches Projekt mit großem Potenzial! Paddel- oder Unternehmererfahrung sind nicht notwendig – unser innovatives Konzept macht es zu einem spannenden ersten Schritt in die Welt der Selbstständigkeit. Bring KAYAKOMAT in deine Stadt!',
        es: 'Iniciar un negocio de alquiler automatizado es un proyecto paralelo de baja inversión y bajo esfuerzo que puede dar excelentes resultados. No se necesita experiencia previa con kayaks ni como emprendedor, nuestro concepto lo convierte en una primera aventura divertida para iniciar tu propio negocio. ¡Lleva KAYAKOMAT a tu ciudad!',
        dk: 'At starte en automatiseret udlejningsvirksomhed, er et projekt med lav investering og lav indsats, som kan give gode resultater! Ingen tidligere erfaring med padling eller som iværksætter er nødvendig. Vores gennemprøvede koncept, gør det til en sjov første satsning ved at starte din egen virksomhed. Tag KAYAKOMAT med til din egen by!',
        fr: 'Lancer une entreprise de location automatisée est un projet secondaire à faible investissement et faible effort qui peut donner d\'excellents résultats ! Aucune expérience préalable en matière de pagayage ou d\'entrepreneuriat n\'est nécessaire, notre concept fait une première aventure amusante dans la création de votre propre entreprise. Amenez KAYAKOMAT dans votre ville !' 
    },
    "learnMoreLink": {
        sv: 'Mer info >>',
        en: 'Learn more >>',
        de: 'Mehr lesen >>',
        es: 'Más información >>',
        dk: 'Mere info >>',
        fr: 'Plus d\'informations >>' 
    },
}