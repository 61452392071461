module.exports = {
    "title": {
        sv: 'Paddling är den perfekta aktiviteten att njuta av tillsammans med vänner, familj eller kollegor.',
        en: 'Paddling is the perfect activity to enjoy with friends, family or colleagues',
        de: 'Paddeln eignet sich hervorragend, um gemeinsam mit Freunden, Familie oder Kollegen zu genießen',
        es: 'Remar es la actividad perfecta para disfrutar con amigos, familiares o compañeros de trabajo.',
        dk: 'Padling er den perfekte aktivitet at nyde sammen med venner, familie eller kolleger',
        fr: 'Le pagayage est l\'activité parfaite à partager avec des amis, la famille ou les collègues' 
    }, 
    "text": {
        sv: 'En rolig och prisvärd AW efter jobbet, en kick-off, en del av en weekendresa eller till och med en dejt!  Visste du att vi även tar emot gruppbokningar?',
        en: 'A fun, affordable afterwork meetup, part of a weekend trip, or even a date!  Did you know we also take group bookings?',
        de: ' Sei es als unterhaltsames und erschwingliches Afterwork-Treffen, Teil eines Wochenendausflugs oder sogar als romantisches Date! Übrigens bieten wir auch Gruppenbuchungen an.',
        es: '¡Un encuentro divertido y asequible después del trabajo, parte de un viaje de fin de semana o incluso una cita! ¿Sabías que también aceptamos reservas de grupos?',
        dk: 'Et sjovt, overkommeligt fyraftensmøde, en del af en weekendtur eller endda en date! Vidste du, at vi også tager imod gruppebookinger?',
        fr: 'Un After-Work amusant et abordable, une excursion un week-end et pourquoi pas un rendez-vous ! Saviez-vous que nous acceptons également les réservations de groupe ?' 
    }, 
}