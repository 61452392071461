import styles from './Background.module.css'

type BackgroundTypes = {
  className?: string
  src?: string
  tint?: string
  children: string | JSX.Element | JSX.Element[]
}

export default function Background({
  children,
  className,
  src,
  tint,
}: BackgroundTypes) {
  return (
    <section
      className={className ? styles[className] : styles.background}
      style={{ backgroundImage: src && `url(${src})` }}
    >
      {tint && (
        <div className={styles.overlay} style={{ backgroundColor: tint }} />
      )}
      {children}
    </section>
  )
}
