import Background from '@library/Background/Background'
import Button from '@library/Button/Button'
import buttonT from '@/translations/v2/ui/buttons.js'
import ColumnContainer from '@layout/Column/Container/Container'
import Column from '@layout/Column/Column'
import Container from '@layout/Container/Container'
import H2 from '@library/Texts/H2'
import Image from 'next/image'
import P from '@library/Texts/Paragraph'
import t from '@/translations/v2/reusable/group'

export default function GroupPaddleInfo({ locale }) {
  return (
    <Background className="backgroundStartPage1">
      <div className="ImageWrapper">
        <Image
          src="/images/KAYAKOMAT_Homepage_Image_Groupbookings.jpg"
          alt="Group of friends having a picnic next to the shore on a sunny evening after paddling kayaks together. Kayaks next to them."
          layout="fill"
          objectFit="cover"
          quality={100}
          priority
        />
      </div>
      <Container>
        <ColumnContainer gap={50} wrap="wrap">
          <Column width={50}>
            <H2>{t.title[locale]}</H2>
            <P size="medium">{t.text[locale]}</P>
            <Button size="medium" url="/">
              {buttonT.findKayakomatButton[locale]}
            </Button>
          </Column>
        </ColumnContainer>
      </Container>
    </Background>
  )
}
