import Button from '@library/Button/Button'
import H5 from '@library/Texts/H5'
import Image from '@library/Media/Image/Image'
import uiTrans from 'translations/ui'

import styles from './Card.module.css'

export default function ProductCard({ locale, name, _id, img, alt }) {
  return (
    <article className={styles.ProductCard}>
      <Image src={img} alt={alt || ''} />
      <section className={styles.ProductCardInner}>
        <H5>{name.replace('KAYAKOMAT', '')}</H5>
        <Button url={`/${locale}/location/${_id}`}>
          {uiTrans.buttonBookNow[locale]}
        </Button>
      </section>
    </article>
  )
}
