import { useState, useRef, useEffect } from 'react'
import Icon from '@library/Icons/Icons'
import countryTrans from '../../../../translations/countries'

import styles from './SelectDropdown.module.css'

export default function SelectDropdown({
  locale,
  items,
  value,
  onChange,
  placeholder,
}) {
  const wrapperRef = useRef(null)
  const [active, setActive] = useState(false)
  const [activeItem, setActiveItem] = useState(value || false)

  useEffect(() => {
    if (!value) {
    }
    setActiveItem(items?.find((i) => i.value === value?.value) || false)
  }, [value])

  const useOutsideClose = (ref) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (active && ref.current && !ref.current.contains(event.target)) {
          setActive(false)
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref, active])
  }

  const setNewValue = (item, index) => {
    onChange(item, index)
    setActive(false)
  }

  useOutsideClose(wrapperRef)

  return (
    <div className={styles.SelectDropdown} ref={wrapperRef}>
      <div
        className={styles.SelectDropdownActive}
        onClick={() => {
          setActive(!active)
        }}
      >
        <div
          className={`${styles.SelectDropDownActiveValue} ${
            !activeItem ? styles.SelectDropDownPlaceholder : ''
          }`}
        >
          {!activeItem ? placeholder : activeItem?.label}
        </div>
        <div className={styles.SelectDropDownToggleArrow}>
          <Icon imgSrc="/icons/arrow-full.svg" maxWidth="20px" />
        </div>
      </div>
      {active && (
        <div className={styles.SelectDropdownList}>
          <div
            className={styles.SelectDropdownListItem}
            onClick={() => setNewValue('undefined', null)}
          >
            {countryTrans['all-countries'][locale]}
          </div>
          {items?.map((i, index) => {
            return (
              <div
                key={index}
                className={styles.SelectDropdownListItem}
                onClick={() => setNewValue(i, index)}
              >
                {i.label}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
