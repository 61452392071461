import { useEffect, useState } from 'react'

import { useRouter } from 'next/router'

import Column from '@/components/v2/layout/Column/Column'
import Container from '@layout/Container'
import H1 from '@library/Texts/H1'
import Image from 'next/image'
import P from '@library/Texts/Paragraph'

import HeroMap from '../map/HeroMap'

import t from 'translations/v2/startPage'
import styles from './Hero.module.css'
import SearchBar from './SearchBar/SearchBar'

export default function Hero({
  countries,
  suppliersList,
  search = null,
  locale,
  ipLocation,
}) {
  const router = useRouter()

  const [mapSettings, setMapSettings] = useState({
    country: 'all',
    lat: 56.638581,
    lng: -30.496454,
    zoom: 2.45,
    usedQueryFromUrl: false,
  })
  const [filters, setFilters] = useState({
    country: { value: undefined },
    textSearch: '',
    loadFromUrl: {
      lat: 0,
      lng: 0,
      zoom: 0,
      loaded: false,
    },
  })

  useEffect(() => {
    //@ts-ignore
    let params = new URL(document.location).searchParams
    let country = params.get('country')
    let lat = params.get('lat')
    let lng = params.get('lng')
    let zoom = params.get('zoom')

    if (lat && lng && zoom) {
      setFilters({
        ...filters,
        country: { value: country ? country : undefined },
        loadFromUrl: {
          lat: Number(lat),
          lng: Number(lng),
          zoom: Number(zoom),
          loaded: true,
        },
      })
    } else if (country) {
      setFilters({ ...filters, country: { value: country } })
    } else {
      const location = async () => {
        const locationFromIP = ipLocation
        const countryFoundInList = countries?.find(
          (c) => c.shortcode === locationFromIP?.countryCode?.toLowerCase()
        )
        if (countryFoundInList) {
          setFilters({
            ...filters,
            country: { value: countryFoundInList.shortcode },
          })
        } else {
          setFilters({ ...filters, country: { value: undefined } })
        }
      }
      location()
    }
  }, [locale, ipLocation])

  const selectLocationFromMap = async ({ country }) => {
    if (!mapSettings.usedQueryFromUrl && filters.loadFromUrl.loaded) {
      setMapSettings({
        country: country,
        lat: filters.loadFromUrl.lat,
        lng: filters.loadFromUrl.lng,
        zoom: filters.loadFromUrl.zoom,
        usedQueryFromUrl: true,
      })
    } else if (
      typeof filters.country.value !== 'undefined' &&
      filters.country.value !== mapSettings.country
    ) {
      setMapSettings({
        ...mapSettings,
        country: country,
        lat: countries.find((c) => c.shortcode === country)?.location.lat,
        lng: countries.find((c) => c.shortcode === country)?.location.lng,
        zoom: countries.find((c) => c.shortcode === country)?.location.zoom,
      })

      const params = new URLSearchParams(window.location.search)
      params.set('country', filters.country.value)
      router.replace(`${router.pathname}?${params}`, undefined, {
        scroll: false,
      })
    } else if (typeof filters.country.value === 'undefined') {
      setMapSettings({
        ...mapSettings,
        country: 'all',
        lat: 56.638581,
        lng: -30.496454,
        zoom: 2.45,
      })
    }
  }

  useEffect(() => {
    selectLocationFromMap({
      country: filters.country.value,
    })
  }, [filters])

  const onMapMove = (settings) => {
    const params = new URLSearchParams(window.location.search)
    params.set('lat', settings.lat)
    params.set('lng', settings.lng)
    params.set('zoom', settings.zoom)
    router.replace(`${router.pathname}?${params}`, undefined, {
      scroll: false,
      shallow: true,
    })
  }

  return (
    <>
      <section className={styles.Hero}>
        <div className={styles.ImageWrapper}>
          <Image
            src="/images/KAYAKOMAT_Hero_Image_Homepage.jpg"
            alt="Couple Paddling"
            layout="fill"
            objectFit="cover"
            quality={100}
            priority
          />
        </div>
        <Container>
          <section className={styles.HeroInner}>
            <Column vAlign="center" hAlign="center">
              <H1>{t.titleSelfService[locale]}</H1>
              <P textAlign="center">{t.subTitleSelfService[locale]}</P>
            </Column>
          </section>
        </Container>
      </section>
      <section className={styles.HeroSearchBar}>
        <Container padding={'small'}>
          <Column>
            <section className={styles.HeroSearchContainer}>
              <SearchBar
                countries={countries}
                locale={locale}
                onChange={(value) => setFilters(value)}
                filters={filters}
                suppliersList={suppliersList}
              />
              <HeroMap
                items={suppliersList}
                locale={locale}
                onMapMove={onMapMove}
                search={search}
                settings={mapSettings}
              />
            </section>
          </Column>
        </Container>
      </section>
    </>
  )
}
