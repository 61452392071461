import { useState } from 'react'
import Lottie from 'lottie-react'
import safteyTrans from '@/translations/booking/instructions'
import afterDark from '@/animations/afterDark.json'
import closeShore from '@/animations/closeShore.json'
import lifeJacket from '@/animations/lifeJacket.json'
import ownRisk from '@/animations/ownRisk.json'
import paddleCompany from '@/animations/paddleCompany.json'
import weatherForcast from '@/animations/weatherForcast.json'
import ContentWrapper from '@layout/ContentWrapper/ContentWrapper'
import Icon from '@library/Icons/Icons'

import styles from './Safety.module.css'
import P from '@library/Texts/Paragraph'

export default function Saftey({ locale }) {
  const [currentStep, setCurrentStep] = useState(0)

  const steps = [
    {
      animation: closeShore,
      instructions: safteyTrans.closeShore[locale],
    },
    {
      animation: lifeJacket,
      instructions: safteyTrans.lifeJacket[locale],
    },
    {
      animation: paddleCompany,
      instructions: safteyTrans.paddleCompany[locale],
    },
    {
      animation: weatherForcast,
      instructions: safteyTrans.weatherForcast[locale],
    },
    {
      animation: afterDark,
      instructions: safteyTrans.afterDark[locale],
    },
    {
      animation: ownRisk,
      instructions: safteyTrans.ownRisk[locale],
    },
  ]

  return (
    <div className={styles.lottieAnimationPlayerWrapper}>
      <div
        className={`${styles.safteyPrevAnimation} ${
          currentStep === 0 ? styles.opacityArrowButton : ''
        }`}
        onClick={() => {
          if (currentStep !== 0) {
            setCurrentStep((prev) => prev - 1)
          }
        }}
      >
        <Icon imgSrc="/icons/arrow.svg" maxWidth="50px" />
      </div>
      <div className={styles.lottieAnimationPlayerContainer}>
        <Lottie animationData={steps[currentStep].animation} loop={true} />
        <div className={styles.safteyInstructionsContainer}>
          <ContentWrapper padding={'0 24px'}>
            <P desktopSize={'medium'} textAlign="center">
              {steps[currentStep].instructions}
            </P>
          </ContentWrapper>
        </div>
      </div>
      <div
        className={`${styles.safteyNextAnimation} ${
          currentStep === steps.length - 1 ? styles.opacityArrowButton : ''
        }`}
        onClick={() => {
          if (currentStep !== steps.length - 1) {
            setCurrentStep((prev) => prev + 1)
          }
        }}
      >
        <Icon imgSrc="/icons/arrow.svg" maxWidth="50px" />
      </div>
    </div>
  )
}
