module.exports = {
    "searchPlaceholder": {
        sv: 'Sök',
        en: 'Search',
        de: 'Suchen',
        es: 'Búsqueda',
        dk: 'Søg',
        fr: 'Recherche' 
    },
    "countryPlaceholder": {
        sv: 'Land',
        en: 'Country',
        de: 'Land',
        es: 'País',
        dk: 'Land',
        fr: 'Pays' 
    }
}