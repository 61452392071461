module.exports = {
    "title": {
        sv: 'Tyckte du om att paddla?',
        en: 'Enjoyed paddling?',
        de: 'Hat dir das Paddeln Spaß gemacht?',
        es: '¿Disfrutaste remando?',
        dk: 'Har du nydt at padle?',
        fr: 'Vous avez apprécié pagayer ?' 
    }, 
    "text": {
        sv: 'Köp en begagnad kajak eller SUP! Vi förnyar vår flotta årligen. Vid säsongsslut har du möjlighet att köpa utrustning. Bra pris, lokal upphämtning och tillbehör inkluderade. Hitta den som passar dig!',
        en: `Buy a 2nd hand kayak or SUP! Our fleet renews yearly. Season's end brings your chance to purchase gear. Great prices, local pickups. Accessories included. Find your fit!`,
        de: 'Kaufe ein gebrauchtes Kajak oder SUP! Unsere Flotte wird jährlich erneuert. Zum Saisonende hast du die Möglichkeit, Ausrüstung zu erwerben. Tolle Preise, Abholung vor Ort möglich. Zubehör inklusive. Finde das passende für dich!',
        es: '¡Compra un kayak o una tabla de paddle surf de segunda mano! Nuestra flota se renueva cada año. El final de la temporada te brinda la oportunidad de adquirir equipo. Buenos precios, recogida local. Accesorios incluidos. ¡Encuentra el que se adapte a ti!',
        dk: 'Køb en 2. hånds kajak eller SUP! Vores flåde fornyes årligt. Sæsonens afslutning giver dig mulighed for at købe udstyr. Gode priser, lokale afhentninger. Tilbehør medfølger. Find din pasform!',
        fr: `Achetez un kayak ou un paddle d'occasion ! Notre flotte est renouvelée chaque année. La fin de la saison est l'occasion pour vous d'acheter de l'équipement. Des prix attractifs, des retraits locaux. Accessoires inclus. Trouvez ce qui vous convient !` 
    },
    "button": {
        sv: 'KÖPA KAJAKER/SUP',
        en: 'SHOP KAYAKS & SUPS',
        de: 'KAJAKS & SUP-BOARDS SHOPPEN',
        es: 'COMPRAR KAYAKS Y TABLAS DE PADDLE SURF',
        dk: 'KØB KAJAK & SUPS',
        fr: 'BOUTIQUE DES KAYAKS ET DES PADDLES'
    }
}