import countryTrans from 'translations/countries'
import ButtonT from '@/translations/v2/ui/buttons'
import MessagesT from '@/translations/v2/ui/messages'
import InputT from '@/translations/v2/ui/inputs'

import Button from '@library/Button/Button'
import Column from '@layout/Column/Column'
import SelectDropdown from '@library/Inputs/SelectDropdown'
import InputTextAutoComplete from '@library/Inputs/TextAutoComplete'

import styles from './SearchBar.module.css'
import inputStyles from '@library/Inputs/Input.module.css'
import Link from 'next/link'

import { hasSupplierAvailableDates } from '../../../../helper/supplierAvailableDate'

export default function Searchbar({
  countries,
  locale,
  onChange,
  filters,
  suppliersList,
}) {
  const mappedList = countries?.map((c, i) => {
    return {
      value: c.shortcode,
      label:
        countryTrans[c?.name.toLowerCase().replace(/\s/g, '')]?.[locale] ||
        c.name.toUpperCase(),
    }
  })

  const updateFilters = (field, value) => {
    onChange({ ...filters, [field]: value })
  }

  return (
    <section className={styles.SearchbarContainer}>
      <Column width={25}>
        <SelectDropdown
          locale={locale}
          items={mappedList}
          value={filters.country}
          onChange={(value, index) => updateFilters('country', value)}
          placeholder={InputT.countryPlaceholder[locale]}
        />
      </Column>
      <Column width={25} grow={true}>
        <InputTextAutoComplete
          className={styles.SearchInput}
          placeholder={InputT.searchPlaceholder[locale]}
          value={filters.textSearch}
          priorityFields={[
            {
              key: 'name',
              prio: 2,
            },
          ]}
          onChange={(value) => {
            updateFilters('textSearch', value)
          }}
          items={suppliersList
            .filter((i) => {
              if (!i.active) {
                return false
              }
              if (
                typeof filters?.country?.value === 'undefined' ||
                filters?.country?.value === 'all' ||
                i.countryId.shortcode == filters?.country?.value
              ) {
                return true
              } else {
                return false
              }
            })
            .map((i) => {
              return {
                _id: i._id,
                address: i.address,
                inactiveBooking: i.inactiveBooking,
                city: i.city,
                countryShortcode: i.countryId.shortcode,
                countryName: i.countryId.name,
                name: i.name,
                postCode: i.postCode,
                // @ts-ignore
                name: i.name,
                openDates: i.openDates,
              }
            })}
          listEmptyMsg={MessagesT.noStationsFound[locale]}
          listItemRender={(item) => {
            const hasSupplierAvailableDate = hasSupplierAvailableDates(
              item.openDates
            )
            return (
              <div
                key={item._id}
                className={[
                  inputStyles.SearchStationItem,
                  item.inactiveBooking &&
                    inputStyles.SearchStationItem_Disabled,
                ].join(' ')}
              >
                <div>
                  <span className={`fi fi-${item.countryShortcode} fis`}></span>
                </div>
                <div className={inputStyles.SearchStationItemInfo}>
                  <a href={`/location/${item._id}`}>
                    <div className={inputStyles.SearchStationItemInfo_name}>
                      {item.name.replace('KAYAKOMAT', '')}
                    </div>
                  </a>
                  <div className={inputStyles.SearchStationItemInfo_address}>
                    {item.address}, {item.postCode} {item.city}
                  </div>
                </div>
                <div>
                  <Button
                    size="small"
                    style={
                      item.inactiveBooking || !hasSupplierAvailableDate
                        ? 'disabled'
                        : 'default'
                    }
                    url={`/location/${item._id}`}
                    disabled={item.inactiveBooking || !hasSupplierAvailableDate}
                  >
                    {item.inactiveBooking || !hasSupplierAvailableDate
                      ? ButtonT.inactiveStationButton[locale]
                      : ButtonT.chooseStationButton[locale]}
                  </Button>
                </div>
              </div>
            )
          }}
        />
      </Column>
      <Column width={25}>
        <Button size="medium" onClick={() => {}}>
          {ButtonT.searchButton[locale]}
        </Button>
      </Column>
    </section>
  )
}
