module.exports = {
  'all-countries': {
    sv: 'ALLA LÄNDER',
    en: 'ALL COUNTRIES',
    de: 'ALLE LÄNDER',
    es: 'TODOS LOS PAÍSES',
    dk: 'ALLE LANDE',
    fr: 'TOUS LES PAYS',
  },
  sweden: {
    sv: 'SVERIGE',
    en: 'SWEDEN',
    de: 'SCHWEDEN',
    es: 'SUECIA',
    dk: 'SVERIGE',
    fr: 'SUÈDE',
  },
  finland: {
    sv: 'FINLAND',
    en: 'FINLAND',
    de: 'FINNLAND',
    es: 'FINLANDIA',
    dk: 'FINLAND',
    fr: 'FINLANDE',
  },
  denmark: {
    sv: 'DANMARK',
    en: 'DENMARK',
    de: 'DÄNEMARK',
    es: 'DINAMARCA',
    dk: 'DANMARK',
    fr: 'DANEMARK',
  },
  germany: {
    sv: 'TYSKLAND',
    en: 'GERMANY',
    de: 'DEUTSCHLAND',
    es: 'ALEMANIA',
    dk: 'TYSKLAND',
    fr: 'ALLEMAGNE',
  },
  spain: {
    sv: 'SPANIEN',
    en: 'SPAIN',
    de: 'SPANIEN',
    es: 'ESPAÑA',
    dk: 'SPANIEN',
    fr: 'ESPAGNE',
  },
  france: {
    sv: 'FRANKRIKE',
    en: 'FRANCE',
    de: 'FRANKREICH',
    es: 'FRANCIA',
    dk: 'FRANKRIG',
    fr: 'FRANCE',
  },
  canada: {
    sv: 'KANADA',
    en: 'CANADA',
    de: 'KANADA',
    es: 'CANADÁ',
    dk: 'CANADA',
    fr: 'CANADA',
  },
  switzerland: {
    sv: 'SCHWEIZ',
    en: 'SWITZERLAND',
    de: 'SCHWEIZ',
    es: 'SUIZA',
    dk: 'SCHWEIZ',
    fr: 'LA SUISSE',
  },
  aland: {
    sv: 'ÅLAND',
    en: 'ÅLAND ISLANDS',
    de: 'ÅLANDINSELN',
    es: 'LAS ISLAS ÅLAND',
    dk: 'ÅLANDSØERNE',
    fr: 'ILES ALAND',
  },
  unitedkingdom: {
    sv: 'STORBRITANNIEN',
    en: 'UNITED KINGDOM',
    de: 'VEREINIGTES KÖNIGREICH',
    es: 'REINO UNIDO',
    dk: 'STORBRITANNIEN',
    fr: 'ROYAUME-UNI',
  },
  ireland: {
    sv: 'IRLAND',
    en: 'IRELAND',
    de: 'IRLAND',
    es: 'IRLANDA',
    dk: 'IRLAND',
    fr: 'IRLANDE',
  },
  austria: {
    sv: 'ÖSTERRIKE',
    en: 'AUSTRIA',
    de: 'ÖSTERREICH',
    es: 'AUSTRIA',
    dk: 'ØSTRIG',
    fr: 'AUTRICHE',
  },
  norway: {
    sv: 'NORGE',
    en: 'NORWAY',
    de: 'NORWEGEN',
    es: 'NORUEGA',
    dk: 'NORGE',
    fr: 'NORVÈGE',
  },
  croatia: {
    sv: 'KROATIEN',
    en: 'CROATIA',
    de: 'KROATIEN',
    es: 'CROACIA',
    dk: 'KROATIEN',
    fr: 'CROATIE',
  },
  albania: {
    sv: 'ALBANIEN',
    en: 'ALBANIA',
    de: 'ALBANIEN',
    es: 'ALBANIA',
    dk: 'ALBANIEN',
    fr: 'ALBANIE',
  },
}
