import Background from '@library/Background/Background'
import ColumnContainer from '@layout/Column/Container/Container'
import Column from '@layout/Column/Column'
import Container from '@layout/Container/Container'
import getLocation from 'helper/ipLocation'
import H2 from '@library/Texts/H2'
import Image from 'next/image'
import P from '@library/Texts/Paragraph'
import ProductCard from '@library/Card/ProductCard'
import { useEffect, useState } from 'react'

import t from '@/translations/v2/reusable/near'

export default function FindStationsNear({ locale, suppliers }) {
  const [stations, setStations] = useState([])
  const [settings, setSettings] = useState({ lat: null, lng: null })

  const productCardImage = [
    '/images/KAYAKOMAT_Homepage_Location_1.jpg',
    '/images/KAYAKOMAT_Homepage_Location_2.jpg',
    '/images/KAYAKOMAT_Homepage_Location_3.jpg',
  ]

  const productCardAltText = [
    'Happy smiling couple paddling a red tandem kayak during a beautiful sunset',
    'Two friends bring their KAYAYKOMAT kayaks from the rental station to the dock.',
    'Young woman paddles a red kayak into the sunset.',
  ]

  const calculateDistance = (
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
  ) => {
    var radlat1 = (Math.PI * lat1) / 180
    var radlat2 = (Math.PI * lat2) / 180
    var theta = lon1 - lon2
    var radtheta = (Math.PI * theta) / 180
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
    dist = Math.acos(dist)
    dist = (dist * 180) / Math.PI
    dist = dist * 60 * 1.1515
    return dist
  }

  useEffect(() => {
    const fetchLocation = async () => {
      const location = await getLocation()
      setSettings({
        lat: location?.lat,
        lng: location?.lng,
      })
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          setSettings({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          })
        });
      }
    }
    fetchLocation()
  }, [])

  useEffect(() => {
    if (!settings.lat || !settings.lng) {
      setStations(suppliers.slice(0, 3))
    }

    const closestStations = suppliers
      .filter((supplier: any) => supplier.active)
      .map((supplier: any) => ({
        ...supplier,
        distance: calculateDistance(
          settings.lat,
          settings.lng,
          supplier.lat,
          supplier.lng
        ),
      }))
      .sort((a: any, b: any) => a.distance - b.distance)
      .slice(0, 3)
    setStations(closestStations)
  }, [settings])

  return (
    <Background className="findStationHighlight">
      <Container>
        <ColumnContainer gap={50} wrap="wrap">
          <Column>
            <ColumnContainer gap={50}>
              <Column width={75}>
                <H2>{t.title[locale]}</H2>
                <P>{t.text[locale]}</P>
              </Column>
            </ColumnContainer>
          </Column>
          <Column>
            <ColumnContainer gap={50}>
              {stations.map((station, index) => (
                <Column key={index} width={33}>
                  <ProductCard
                    {...station}
                    img={productCardImage[index]}
                    locale={locale}
                    alt={productCardAltText[index]}
                  />
                </Column>
              ))}
            </ColumnContainer>
          </Column>
        </ColumnContainer>
      </Container>
    </Background>
  )
}
