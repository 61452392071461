module.exports = {
    "title": { //hidden for now
        sv: 'HITTA EN KAYAKOMAT NÄRA DIG ',
        en: 'FIND A KAYAKOMAT RENTAL LOCATION NEAR YOU',
        de: 'FINDE EINE KAYAKOMAT STATION IN DEINER NÄHE ',
        es: 'ENCUENTRA UNA UBICACIÓN DE ALQUILER DE KAYAKOMAT CERCA DE TI',
        dk: 'FIND EN KAYAKOMAT NÆR DIG',
        fr: 'TROUVEZ UNE STATION DE LOCATION KAYAKOMAT PRÈS DE CHEZ VOUS ' 
    }, 
    "text": { //hidden for now
        sv: 'Du hittar KAYAKOMATs uthyrningsstationer i Sverige, Finland, Kanada, Tyskland, Frankrike, Danmark och Spanien, med fler länder på väg! Att komma ut på vattnet och skapa minnesvärda upplevelser tillsammans ska vara enkelt, därför finns KAYAKOMAT i de flesta städer.  Spana in uthyrningsplatser nära dig, eller på ditt nästa resmål.',
        en: 'You find us in Sweden, Finland, Canada, Germany, France, Denmark, and Spain, with more countries to come! Every town deserves a KAYAKOMAT, for easy access to the water and memorable outdoor  experiences.  Check out locations near you, or at your next travel destination. ',
        de: 'Du findest uns in Schweden, Finnland, Kanada, Deutschland, Frankreich, Dänemark und Spanien, und weitere Länder folgen! Jede Stadt verdient einen KAYAKOMAT, für unvergessliche Outdoor-Erlebnisse. Schau dir Standorte in deiner Nähe an oder bei deinem nächsten Reiseziel.  ',
        es: 'Nos encontrarás en Suecia, Finlandia, Canadá, Alemania, Francia, Dinamarca y España, ¡con más países por venir! Cada ciudad merece un KAYAKOMAT para acceder fácilmente al agua y tener experiencias memorables al aire libre. Echa un vistazo a las ubicaciones cerca de ti o en tu próximo destino de viaje.',
        dk: 'Du finder os i Sverige, Danmark, Finland, Canada, Tyskland, Frankrig og Spanien, med flere lande på vej! Hver by fortjener en KAYAKOMAT, for nem adgang til vandet og mindeværdige friluftsoplevelser. Tjek steder i nærheden af dig eller på din næste rejsedestination.',
        fr: 'Vous nous trouverez en Suède, en Finlande, au Canada, en Allemagne, en France, au Danemark et en Espagne, avec d\'autres pays à venir ! Chaque ville mérite un KAYAKOMAT, pour un accès facile à l\'eau et des expériences de plein air mémorables. Découvrez les emplacements près de chez vous ou lors de votre prochaine destination de voyage.' 
    }, 
}